import * as React from "react";

import Logo from "~/public/logo.svg";

const Footer: React.VoidFunctionComponent = () => {
  return (
    <div className="mt-16 bg-slate-50/70">
      <div className="mx-auto grid max-w-7xl grid-cols-10 gap-8 py-20 px-8">
        <div className="col-span-12 md:col-span-4">
          <Logo className="mb-4 w-32 text-gray-400" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
