import Head from "next/head";
import React from "react";

import Hero from "./home/hero";
import PageLayout from "./page-layout";

const Home: React.VoidFunctionComponent = () => {
  return (
    <PageLayout>
      <Head>
        <meta
          name="description"
          content="Erstelle Umfragen und stimme ab, um den besten Tag oder die beste Zeit zu finden. Eine kostenlose Alternative zu Doodle."
        />
        <title>GLOBI - Die kleine Umfrageplattform</title>
      </Head>
      <Hero />
    </PageLayout>
  );
};

export default Home;
